
import { Component, Vue } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'
import { CampusInfo } from '@/types/school'
import { FileInfo } from '@/types/common'
import UploadFile from '@/components/uploadFile/Index.vue'

@Component({
  components: { UploadFile }
})

export default class Add extends Vue {
  private info: CampusInfo = {
    projectId: '',
    name: '',
    content: '',
    fileList: []
  }

  private rules = {
    projectId: [
      { required: true, message: '请选择项目名称', trigger: 'change' }
    ],
    name: [
      { required: true, message: '请输入院校名称', trigger: 'change' }
    ],
    content: [
      { required: true, message: '请输入院校简介信息', trigger: 'change' }
    ],
    fileList: [
      { required: true, message: '请选择学校美图', trigger: 'change' }
    ]
  }

  private submitShow = false

  get infoId () {
    return this.$route.params.id as string
  }

  get projectList () {
    return this.$store.state.projectList
  }

  created () {
    this.infoId && this.getDetail()
  }

  getDetail () {
    this.$axios.get(this.$apis.school.selectCampusInfoByInfoId, {
      infoId: this.infoId
    }).then(res => {
      this.info = res || {}
    })
  }

  // 文件上传成功返回
  onSuccess (file: FileInfo) {
    this.info.fileList.push(file)
  }

  imgRemove (index: number) {
    this.info.fileList.splice(index, 1)
  }

  onSubmit () {
    (this.$refs.info as ElForm).validate(valid => {
      if (valid) {
        this.submitShow = true
        const urls = this.infoId ? this.$apis.school.updateCampusInfo : this.$apis.school.insertCampusInfo
        const info = this.infoId ? {
          ...this.info,
          infoId: this.infoId
        } : {
          ...this.info
        }
        this.$axios.post(urls, info).then(() => {
          const text = this.infoId ? '编辑成功' : '新增成功'
          this.$message.success(text)
          this.$router.push({ name: 'campusList' })
        }).finally(() => {
          this.submitShow = false
        })
      }
    })
  }
}
